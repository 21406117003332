import { Box, IconButton, Popover, styled } from "@material-ui/core";
import React from "react";

const KebabBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const CustomIconButton = styled(IconButton)({
  "& .MuiIconButton-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3.333px 0px"
  }
});

const VerticalDotsIcon = styled("img")({
  width: "20px",
  height: "20px"
});

const CustomMenu = styled(Popover)({
  overflow: "visible",
  "& .MuiMenu-list": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "13px"
  },

  "& .MuiList-padding": {
    padding: "0px"
  }
});

const PopoverStyledBox = styled(Box)({
  boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11) !important",
  backgroundColor: "#ffffff",
  marginTop: "11px",
  padding: "9px 23px 11px 10px",
  left: "0",
  position: "relative",
  '@media (max-width:600px)':{
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1) !important",
  }
});

const UpArrow = styled("span")({
  width: 0,
  height: 0,
  borderLeft: "7.5px solid transparent",
  borderRight: "7.5px solid transparent",
  borderBottom: "10px solid #ffffff",
  top: "-10px",
  left: "12px",
  position: "absolute"
});

const OptionItem = styled("div")({
  color: "#7D7D7F !important",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  cursor: "pointer",
  lineHeight: "19.2px",
  height: "19px",
  marginBottom: "13px",
  "&:hover": {
    color: "#34373A !important",
    backgroundColor: "#FFF !important"
  },
  "&:last-child": {
    marginBottom: "0px"
  }
});

interface KebabOptionsPropTypes {
  verticalDotIcon: string;
  onToggleOption: any;
  open: boolean;
  anchorEl: any;
  onSelect: any;
  options: any;
  testId?: string;
}

const KebabOptions = (props: KebabOptionsPropTypes) => {
  const {
    verticalDotIcon,
    onToggleOption,
    open,
    anchorEl,
    onSelect,
    options,
    testId,
  } = props;

  return (
    <KebabBox>
      <CustomIconButton data-test-id={testId} onClick={onToggleOption}>
        {verticalDotIcon && <VerticalDotsIcon src={verticalDotIcon} alt="" />}
      </CustomIconButton>
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        data-test-id={testId}
        onClose={onToggleOption}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            overflow: "visible"
          }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <PopoverStyledBox>
          {options &&
            options.map((option: any, index: number) => (
              <OptionItem
                data-test-id="kebab_options_id"
                key={`kboption_${index}`}
                onClick={() => onSelect(option)}
              >
                {option}
              </OptionItem>
            ))}
        </PopoverStyledBox>
      </CustomMenu>
    </KebabBox>
  );
};

export default KebabOptions;
