import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Paper,
  TextField,
  Radio,
  Chip,
  Snackbar,
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import Layout from "../../dashboard/src/Layout.web";
import CustomizedSteppers from "../../../components/src/CustomizedSteppers";
import CloseIcon from '@material-ui/icons/Close';
import {checkbox, checked} from "../../customform/src/assets"
import Autocomplete from "@material-ui/lab/Autocomplete";
import {closeChip,editIcon,trashIcon,successCheck,arrowDown} from "./assets"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const theme = createTheme({
  typography: {
    fontFamily:'Lato'
  },
});

const CustomTableContainer = styled(TableContainer)({
	maxHeight: "500px",
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	padding: '12px 24px',
	paddingLeft: 0,
  overflowY:'scroll',
  scrollbarWidth:'none',
	'@media(max-width: 600px)': {
		padding: 0
	}
}); 

const CustomTable = styled(Table)({
	width: "100%",
	borderCollapse: "separate",
  marginBottom:'20px'
});
const CustomTableHead = styled(TableHead)({
	backgroundColor: "#F5F5F5",
	borderRadius: "12px",
});
const AutoBox = styled(Box)({
	'& div button+button': {
    marginRight: 6
  },
});
const CustomTableRowHead = styled(TableRow)({
	// borderRadius: "20px !important",
	overflow: "hidden",
	"& :first-child": {
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	"& :last-child": {
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
});
const CustomTableCellHead = styled(TableCell)({
	minHeight: "48px",
	padding: "10px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellHeadUsername = styled(TableCell)({
	minHeight: "48px",
	padding: "10px 18px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CustomSpacing = styled("div")({
	height: 16,
});
const CustomTableBody = styled(TableBody)({
	color: "inherit",
});
const CustomTableRow = styled(TableRow)({});
const CustomTableCellUsername = styled(TableCell)({
	borderBottom: "1px solid #F1F5F9",
	padding: "8px 8px 8px 18px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CustomTableCell = styled(TableCell)({
	borderBottom: "1px solid #F1F5F9",
	padding: "8px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CreateButton = styled(Button)({
  color:'#43D270',
  fontSize:'12px',
  fontWeight:700,
  textTransform:'capitalize' as 'capitalize',
  '& :hover':{
    color:'#43D270',
  }
})

const CreateTaskButton = styled(Button)({
  background:'#43D270',
  borderRadius:'8px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  textTransform:'capitalize' as 'capitalize',
  boxShadow:'none',
  width:'195px',
  height:'40px',
  '&:hover':{
    background:'#43D270',
    color:'#34373A',
    boxShadow:'none'
  }
})

const TaskNameField = styled(TextField)({
  width:'100%',
    fontSize:'14px',
    color:'#34373A',
  '& div':{
    borderRadius:'8px',
    '& :focus':{
      outline:'auto',
      borderRadius:'8px',
      zIndex: 1,
    },
  }
})

const DescriptionField = styled(TextField)({
  width:'100%',
  '& :focus':{
    outline:'auto',
    borderRadius: '8px',
  },
  '& div':{
    borderRadius: '8px',
    '& textarea':{
      position: 'relative',
      fontFamily: 'Lato',
      fontSize: '16px'
    },
    '&.MuiOutlinedInput-multiline-408':{
      '& :focus':{
        padding:'0',
      }
    },
  },
  '& placeholder':{
    top:'-14px'
  },
})

const NoButton = styled(Button)({
 borderColor:'#D9D9D9',
 borderRadius:'8px',
 color:'#34373A',
 fontSize:'12px',
 fontWeight:700,
 height:'32px',
 boxShadow:'none',
 textTransform:'capitalize',
 width:'97px',
 '&:hover':{
  borderColor:'#D9D9D9',
  color:'#34373A'
 }
})

const YesButton = styled(Button)({
 background:'#43D270',
 height:'32px',
 borderRadius:'8px',
 padding:'0px 20px',
 color:'#34373A',
 fontSize:'12px',
 fontWeight:700,
 boxShadow:'none',
 textTransform:'capitalize',
 width:'97px',
 '&:hover':{
  background:'#43D270',
  color:'#34373A',
  boxShadow:'none'
 }
})

const SaveProcedButton = styled(Button)({
  background:'#43D270',
  borderRadius:'8px',
  width:'184.5px',
  height:'40px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  boxShadow:'none',
  textTransform:'capitalize',
  '&:hover':{
    background:'#43D270',
    color:'#34373A',
    boxShadow:'none'
  }
})

const BackButton = styled(Button)({
  borderRadius:'8px',
  borderColor:'#000',
  width:'184.5px',
  height:'40px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  boxShadow:'none',
  textTransform:'capitalize',
  '&:hover':{
    borderColor:'#000',
    color:'#34373A',
    boxShadow:'none'
  }
})

const criticalOptions = [
  {value:'critical',label:'Critical'},
  {value:'major',label:'Major'},
  {value:'minor',label:'Minor'},

]

const renderSubCategory = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.sub_category_name}</Typography>
    </li>
  );
}
const renderAssignee = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.first_name} {option.last_name}</Typography>
    </li>
  );
}
const renderCritical = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.label}</Typography>
    </li>
  );
}

// Customizable Area End

import GoalManagementController, {
  Props,
  configJSON,
} from "./GoalManagementController";

export default class GoalManagement extends GoalManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
        return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Layout>
          <Box style={webStyle.mainWrapper}>
              <Box>
                <Typography style={webStyle.actionText}>{configJSON.actionPlan}</Typography>
                <Typography style={webStyle.actionDescription}>{configJSON.actionDescription}</Typography>
              </Box>
             <CustomizedSteppers
             data-testid="customized-stepper"
              getSteps={this.state.categories}
              getStepContent={this.getStepContent}
              activeStep={this.state.activeStep}
             />
             <div style={{height:'583px'}}>
             <CustomTableContainer>
             <CustomTable aria-label="task table">
             <CustomTableHead>
             <CustomTableRowHead>
                <CustomTableCellHeadUsername>{configJSON.taskNameHeader}</CustomTableCellHeadUsername>
								<CustomTableCellHead>{configJSON.subCategoryHeader}</CustomTableCellHead>
								<CustomTableCellHead>{configJSON.assigneeHeader}</CustomTableCellHead>
								<CustomTableCellHead>{configJSON.criticalityHeader}</CustomTableCellHead>
								<CustomTableCellHead>{configJSON.startDate}</CustomTableCellHead>
								<CustomTableCellHead>{configJSON.EndDate}</CustomTableCellHead>
								<CustomTableCellHead></CustomTableCellHead>
								<CustomTableCellHead></CustomTableCellHead>
             </CustomTableRowHead>
             </CustomTableHead>
              {this.state.allTasks &&
              this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item => 
                <>
                {item.tasks.map(task =>
                  <>
                  <CustomSpacing />
                  <CustomTableBody>
                   <CustomTableRow>
                   <CustomTableCellUsername>{task.task_name}</CustomTableCellUsername>
                   <CustomTableCell>{task.task_sub_category_name}</CustomTableCell>
                   <CustomTableCell>
                    <AvatarGroup spacing="small">
                    {task.assignees.map(data =>
                        <Avatar src={data?.image} style={{width:'38px',height:'36px',border:'3px solid #fff'}}/>
                      )}
                    </AvatarGroup>
                   </CustomTableCell>
                   {task.criticality === "minor" &&
                   <CustomTableCell>
                    <div  style={{...webStyle.criticalTable,color:'#ecb922'}}>
                    <FiberManualRecordIcon style={{fontSize:'12px'}}/>
                    Minor
                    </div>
                   </CustomTableCell>
                   }
                   {task.criticality === "major" &&
                   <CustomTableCell>
                    <div  style={{...webStyle.criticalTable,color:'#F96C30'}}>
                    <FiberManualRecordIcon style={{fontSize:'12px'}}/>
                    High
                    </div>
                   </CustomTableCell>
                   }
                   {task.criticality === "critical" &&
                   <CustomTableCell>
                    <div  style={{...webStyle.criticalTable,color:'#7166F1'}}>
                    <FiberManualRecordIcon style={{fontSize:'12px'}}/>
                    Medium
                    </div>
                   </CustomTableCell>
                   }
                   <CustomTableCell>{task.start_date}</CustomTableCell>
                   <CustomTableCell>{task.end_date}</CustomTableCell>
                   <CustomTableCell data-testid={"edit-" + task.id} onClick={() => this.handleEdit(task.id)} style={{cursor:'pointer'}}>{<img src={editIcon}/>}</CustomTableCell>
                   <CustomTableCell data-testid={"delete-" + task.id} onClick={() => this.handleOpenDeleteModal(task.id)} style={{cursor:'pointer'}}>{<img src={trashIcon}/>}</CustomTableCell>
                   </CustomTableRow>
                  </CustomTableBody>
                  </>
                )}
              </>
              )
              }
             </CustomTable>
             </CustomTableContainer>
             {this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item => {
              return (
                <>
                {item.tasks.length > 0 ?
                <div>
                <CreateButton
               onClick={this.handleCreateTaskForm}
               data-testid="create-button-one"
               >{configJSON.addMoreButton}</CreateButton>
               
             </div> :
             <div style={webStyle.creatButtonDiv}>
             <Typography style={webStyle.noTaskText}>{configJSON.noTask}</Typography>
             <CreateButton
             onClick={this.handleCreateTaskForm}
             data-testid="create-button"
             >{configJSON.createButton}</CreateButton>
           </div>
              }
                </>
              )
             })} 
             </div>
             
             
             {this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item => {
              return(
                <>
                {item.tasks.length > 0 &&
                   <Box style={webStyle.endButtonBox}>
                <Box style={{display:'flex',gap:'20px'}}>
                  {this.state.activeStep > 0 &&
                  <BackButton data-testid="back-button" variant="outlined" onClick={this.handleBackStepper}>
                    {configJSON.back}
                  </BackButton>
                  }
                  {this.state.categories.length === this.state.activeStep + 1 ?
                  <SaveProcedButton data-testid="complete-button" variant="contained" onClick={this.handleComplete}>
                  {configJSON.complete}
                  </SaveProcedButton> :
                  <SaveProcedButton data-testid="save-proceed" variant="contained" onClick={this.handleProceed}>
                  {configJSON.saveProceed}
                  </SaveProcedButton>
                }
               </Box>
               </Box>
             }
                </>
              )
             })}
            
          </Box>
          <Modal 
          open={this.state.createTaskForm} 
          onClose={this.handleCloseCreateTaskForm} 
          style={webStyle.modalWrapper} 
          data-testid="task-modal"
          >
          <Paper style={webStyle.mdalPaper}>
            <Box style={webStyle.createModalFirstDiv}>
              <Typography style={webStyle.createTaskText}>{configJSON.CreateTask}</Typography>
              <CloseIcon onClick={this.handleCloseCreateTaskForm}/>
            </Box>
            <Typography style={webStyle.createTaskDescr}>{configJSON.createTaskDescription}</Typography>
            <Box style={webStyle.textFiledBox}>
              <Box style={webStyle.fieldBox}>
             <Typography style={webStyle.taskNameLabel}>{configJSON.taskName}</Typography>
              <TaskNameField
              placeholder={configJSON.taskNamePlaceholder}
              variant="outlined"
              onChange={this.handleChangeTaskName}
              value={this.state.taskNameValue}
              data-testid="task-name"
              error={this.state.error && !this.state.taskNameValue}
              />
              {(this.state.error && this.state.taskNameValue === "") &&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                }
              </Box>
              <AutoBox style={webStyle.fieldBox}>
                <Typography style={webStyle.taskNameLabel}>{configJSON.selectSubCategory}</Typography>
              <Autocomplete
                    id="subcategory"
                    options={this.state.subCategoryOption}
                    disableCloseOnSelect
                    value={this.state.subCategoryOption.find(option => option.id === this.state.subCategoryValue)}
                    data-testid="sub-category-field"
                    onChange={(event, value) => this.setState({subCategoryValue:value?.id})}
                    closeIcon={false}
                    popupIcon={<div style={webStyle.arrowDiv}>
                        <img src={arrowDown}/>
                    </div>}
                    renderInput={(params) =>
                      <TextField
                          {...params}
                          name="memberlist"
                          placeholder="Select one from the list"
                          InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: (this.state.error && this.state.subCategoryValue === 0) ? webStyle.autoErrorComplete:webStyle.autoComplete
                          }}
                      />
                    }
                    renderOption={renderSubCategory}
                    PopperComponent={({ children, ...other }) => (
                        <div {...other} style={webStyle.modelSubpopper}>
                            {children}
                        </div>
                    )}
                    getOptionLabel={(option: any) => option.sub_category_name}
                />
                {(this.state.error && this.state.subCategoryValue === 0) &&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                }
              </AutoBox>
            </Box>
            <Box style={webStyle.textFiledBox}>
            <AutoBox style={webStyle.fieldBoxAssing}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.SelectAssignee}</Typography>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              data-testid="assignee-field"
              value={this.state.assigneeValue}
              options={this.state.assigneeOption}
              disableCloseOnSelect
              onChange={this.handleChangeAssignee}
              closeIcon={false}
              popupIcon={<div style={webStyle.arrowDiv}>
                        <img src={arrowDown}/>
                    </div>}
              renderTags={(tagValue, getTagProps) =>
                tagValue.slice(0,3).map((option, index) => (
                  <Chip
                    label={`${option.first_name} ${option.last_name}`}
                    {...getTagProps({ index })}
                    style={{
                      background:'#ECFBF1',
                      borderRadius:'8px',
                      color:'#329E54',
                      fontSize:'12px',
                      fontWeight:500,
                      fontFamily:'Lato',
                      padding:'10px 3px'
                    }}
                    deleteIcon={<img src={closeChip} style={{width:'14px'}}/>}
                  />
                ))
              }
              PopperComponent={({ children, ...other }) => (
                  <div {...other} style={webStyle.modelpopper}>
                      {children}
                  </div>
              )}
              getOptionLabel={(option) =>  `${option.first_name} ${option.last_name}`}
              renderInput={(params:any) => 
                <TextField
                    {...params}
                    name="memberlist"
                    placeholder={this.state.assigneeValue.length === 0 ?"Select one from the list":""}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: (this.state.error && this.state.assigneeValue.length === 0) ? webStyle.autoErrorComplete :webStyle.autoComplete,
                        type: 'search'
                    }}
                />
              }
              renderOption={renderAssignee}
            />
            {(this.state.error && this.state.assigneeValue.length === 0) &&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
                }
            </AutoBox>
            <Box style={webStyle.dateMainBox}>
            <Box style={webStyle.fieldBox}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.StartDate}</Typography>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker 
                    data-testid="start-date"
                    placeholder="DD-MM-YYYY"
                    InputProps={{
                      disableUnderline: true,
                     }}
                    format="DD-MM-YYYY"
                    autoOk
                    openTo="date"
                    style={(this.state.error && this.state.startDate === null) ? webStyle.datetimeinputError :webStyle.datetimeinput}
                    fullWidth
                    disablePast
                    value={this.state.startDate}
                    onChange={this.handleStartDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
              </MuiPickersUtilsProvider>
              {(this.state.error && this.state.startDate === null)&&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
              }
            </Box>
            <Box style={webStyle.fieldBox}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.EndDate}</Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker 
                    data-testid="end-date"
                    placeholder="DD-MM-YYYY"
                    InputProps={{
                      disableUnderline: true,
                     }}
                    format="DD-MM-YYYY"
                    autoOk
                    openTo="date"
                    minDate={this.state.startDate !== null ? this.state.startDate : ''}
                    style={(this.state.error && this.state.endDate === null) ? webStyle.datetimeinputError :webStyle.datetimeinput}
                    fullWidth
                    disablePast
                    value={this.state.endDate}
                    onChange={this.handleChangeEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
              </MuiPickersUtilsProvider>
              {(this.state.error && this.state.endDate === null)&&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
              }
            </Box>
            </Box>
            </Box>
            <Box style={webStyle.textFiledBox}>
            <AutoBox style={webStyle.fieldBoxAssing}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.SelectCriticality}</Typography>
              <Autocomplete
                    id="critical"
                    options={criticalOptions}
                    data-testid="criticality-field"
                    onChange={(event, value) => this.setState({criticalValue:value?.value})}
                    disableCloseOnSelect
                    closeIcon={false}
                    popupIcon={<div style={webStyle.arrowDiv}>
                        <img src={arrowDown}/>
                    </div>}
                    PopperComponent={({ children, ...other }) => (
                        <div {...other} style={webStyle.modelpopper}>
                            {children}
                        </div>
                    )}
                    getOptionLabel={(option: any) => option.label}
                    renderOption={renderCritical}
                    renderInput={(params) =>
                      <TextField
                          {...params}
                          name="memberlist"
                          placeholder="Select one from the list"
                          InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: (this.state.error && this.state.criticalValue === "") ? webStyle.autoErrorComplete:webStyle.autoComplete
                          }}
                      />
                    }
                    value={criticalOptions.find(option => option.value === this.state.criticalValue)}
                />
                {(this.state.error && this.state.criticalValue === "")&&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
              }
            </AutoBox>
            </Box>
            <Box style={webStyle.textFiledBox}>
            <Box style={webStyle.fieldBox}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.Description}</Typography>
              <TaskNameField
              placeholder={configJSON.descriptionPlaceholder}
              variant="outlined"
              data-testid="description"
              onChange={this.handleChangeDescription}
              value={this.state.description}
              error={this.state.error && this.state.description === ""}
              />
              {(this.state.error && this.state.description === "")&&
                <Typography style={webStyle.errorMessage}>{configJSON.errorMessage}</Typography>
              }
            </Box>
            </Box>
            <Box style={webStyle.buttonBox}>
              <CreateTaskButton data-testid="create-task-button" onClick={this.handleCreateTask}>{configJSON.CreateTask}</CreateTaskButton>
            </Box>
          </Paper>
          </Modal>
          <Modal 
          open={this.state.deleteModal} 
          onClose={this.handleCloseDeleteModal} 
          style={webStyle.modalWrapper} 
          data-testid="delete-modal"
          >
          <Paper style={webStyle.deleteModalPaper}>
              <Typography style={webStyle.deleteMessage}>{configJSON.deleteConfirmationMessage}</Typography>
              <Box style={webStyle.deletebuttonBox}>
                <NoButton variant="outlined" data-testid="no-delete-task" onClick={this.handleCloseDeleteModal}>{configJSON.noButton}</NoButton>
                <YesButton variant="contained" data-testid="yes-delete-task" onClick={this.handleDeleteTask}>{configJSON.yesButton}</YesButton>
              </Box>
          </Paper>
          </Modal>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.toastOpen}
            autoHideDuration={4000}
            data-testid="toast"
            onClose={this.handleToastClose}
            message={configJSON.toastMessage}
            action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleToastClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
            }
          />
          <Modal 
          open={this.state.completeModal} 
          onClose={this.handleCloseCompleteModal} 
          style={webStyle.modalWrapper} 
          data-testid="complete-modal"
          >
          <Paper style={webStyle.deleteModalPaper}>
              <Typography style={webStyle.deleteMessage}>{configJSON.completeConfirmationMessage}</Typography>
              <Box style={webStyle.deletebuttonBox}>
                <NoButton data-testid="no-complete-task" variant="outlined" onClick={this.handleCloseCompleteModal}>{configJSON.noButton}</NoButton>
                <YesButton variant="contained" onClick={this.handleCompleteTaskApiCall} data-testid="yes-complete-task">{configJSON.yesButton}</YesButton>
              </Box>
          </Paper>
          </Modal>
          <Modal 
          open={this.state.completedTaskModal} 
          onClose={this.handleCloseCompleteTaskModal} 
          style={webStyle.modalWrapper} 
          data-testid="complete-task-modal"
          >
          <Paper style={webStyle.completedModalPaper}>
              <img src={successCheck} />
              <Typography style={webStyle.sucsessTitle}>{configJSON.successTitle}</Typography>
              <Typography style={webStyle.successMessage}>{configJSON.successMessage}</Typography>
          </Paper>
          </Modal>
        </Layout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    margin:'50px 20px 20px',
    position:"relative" as "relative"
  },
  actionText:{
    fontSize:'22px',
    fontWeight:700,
    color:'#34373A',
    lineHeight:'27.5px'
  },
  actionDescription:{
    fontSize:'12px',
    color:'#7D7D7F',
    lineHeight:'19.2px',
    width:'70%',
    margin:'10px 0'
  },
  noTaskText:{
    fontSize:'10px',
    fontWeight:500,
    lineHeight:'16px',
    color:'#7D7D7F',
  },
  creatButtonDiv:{
    textAlign:'center' as 'center',
    marginTop:'40px'
  },
  modalWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mdalPaper:{
    width: '100%',
    maxWidth: "899px",
    padding: '30px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    maxHeight: '90vh',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none'
  },
  deleteModalPaper:{
    width: '100%',
    maxWidth: "334px",
    padding: '30px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none'
  },
  completedModalPaper:{
    width: '100%',
    maxWidth: "455px",
    padding: '40px 30px 20px',
    height:'241px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none',
    textAlign:'center' as 'center'
  },
  createModalFirstDiv:{
    display:'flex',
    justifyContent:'space-between'
  },
  createTaskText:{
    fontSize:'18px',
    fontWeight:700,
    lineHeight:'25.2px',
    color:'#34373A'
  },
  createTaskDescr:{
    fontSize:'14px',
    lineHeight:'24px',
    color:'#34373A',
    padding:'5px 0'
  },
  textFiledBox:{
    display:'flex',
    gap:'20px',
    marginTop:'15px'
  },
  taskNameLabel:{
    fontFamily:'Lato',
    fontSize:'15px',
    marginBottom:'4px'
  },
  fieldBox:{
    width:'100%'
  },
  fieldBoxAssing:{
    width:'50%'
  },
  optionName:{
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: '14px'
  },
  modelpopper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderBottom:'none',
    boxShadow: 'none !important',
    padding: '0px !important',
},
modelSubpopper:{
  maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderBottom:'none',
    boxShadow: 'none !important',
    padding: '0px !important',
},
autoComplete: {
  minHeight: "48px",
  borderRadius: '8px',
  border: "1px solid #D9D9D9",
  padding: '11px',
  boxShadow: 'none'
},
autoErrorComplete:{
  minHeight: "48px",
  borderRadius: '8px',
  border: "1px solid #f44336",
  padding: '11px',
  boxShadow: 'none'
},
errorMessage:{
 color:'#f44336',
 padding:'1px',
 fontSize:'14px'
},
dateMainBox:{
  display:'flex',
  gap:'20px',
  width:'50%'
},
buttonBox:{
  textAlign:'center' as 'center',
  margin:'40px 0 10px'
},
arrowDiv:{ 
  height: 26,
  width: 26, 
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
},
datetimeinput: {
  width: '100%',
  display: 'flex',
  padding: '13px 10px 8px',
  alignItems: 'center',
  alignSelf: 'stretch',
  border: "#00000024 solid 1px",
  borderRadius: "8px",
  cursor: "pointer",
},
datetimeinputError:{
  width: '100%',
  display: 'flex',
  padding: '13px 10px 8px',
  alignItems: 'center',
  alignSelf: 'stretch',
  border: "#f44336 solid 1px",
  borderRadius: "8px",
  cursor: "pointer",
  color:'#f44336'
},
criticalTable:{
  display:'flex',
  alignItems:'center',
  gap: '6px'
},
deleteMessage:{
  color:'#34373A',
  lineHeight:'24px',
  margin:'10px 0 40px'
},
deletebuttonBox:{
  display:'flex',
  alignItems:'center',
  gap:'20px',
  justifyContent:'center'
},
endButtonBox:{
},
sucsessTitle:{
  fontSize:'24px',
  fontWeight:700,
  lineHeight:'30px',
  color:'#0F172A',
  textAlign:'center' as 'center',
  margin:'30px 0 10px'
},
successMessage:{
  fontSize:'17px',
  lineHeight:'26px',
  color:'#34373A',
  textAlign:'center' as 'center'
}
};
// Customizable Area End
