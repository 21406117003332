import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Task, ViewMode } from "gantt-task-react";
import { Alert } from "react-native";
import moment from "moment";
import { TaskType } from "gantt-task-react/dist/types/public-types";

interface APIData {
  attributes: {
    project_start_date: string;
    project_end_date: string;
    task_name: TaskType;
    percentage: number;
  };
  id: string;
  type: TaskType;
}
// Customizable Area End

export const configJSON = require("./config");
export const ganttData = require("./constants.json");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ganttChartView: ViewMode;
  ganttChartTasks: Task[];
  ganttChartTasksMobile: Task[];
  isChecked: boolean;
  ganttChartColumnWidth: number;
  minGrid: string;
  maxGrid: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  name: string;
  progress: number;
  start: Date;
  end: Date;
  // Customizable Area End
}

export default class GanttChartController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ganttChartView: ViewMode.Day,
      ganttChartTasks: ganttData,
      ganttChartTasksMobile: ganttData,
      isChecked: true,
      ganttChartColumnWidth: 80,
      minGrid: "2023-01-01",
      maxGrid: "2023-03-31",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.getWaterFallData();
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id == getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        this.setState({
          ganttChartTasks: this.responseFormatter(responseJson?.data, "web"),
        });
        this.setState({
          ganttChartTasksMobile: this.responseFormatter(
            responseJson?.data,
            "mob"
          ),
        });
      }
    }
    // Customizable Area End
  }

  responseFormatter = (data: [], platform: string) => {
    let formatedData = [];
    if (data?.length > 0) {
      formatedData = data?.map((item: APIData) => ({
        start: new Date(item?.attributes?.project_start_date),
        end: new Date(item?.attributes?.project_end_date),
        name: item?.attributes?.task_name,
        id: item?.id,
        progress:
          platform === "web"
            ? item?.attributes?.percentage
            : item?.attributes?.percentage / 100,
        type: item?.type,
        project: "ProjectSample",
      }));
      const startDates = formatedData.map((response) => response.start);
      const endDates = formatedData.map((response) => response.end);
      const dates = [...startDates, ...endDates];

      // Convert date strings to moment objects
      const momentDates = dates.map((dateString) => moment(dateString));

      // Find the earliest and latest dates
      const startDate = moment.min(momentDates).subtract(3, "days");
      const endDate = moment.max(momentDates).add(5, "days");

      this.setState({ minGrid: startDate.format("YYYY-MM-DD") });
      this.setState({ maxGrid: endDate.format("YYYY-MM-DD") });
    } else {
      formatedData = ganttData;
    }

    return formatedData;
  };

  getWaterFallData(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGanttChartWaterfallData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGanttChartData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onGanttChartPress = (text: SS) => {
    let message = `${moment(text?.start).format("YYYY-MM-DD")} - ${moment(
      text.end
    ).format("YYYY-MM-DD")}\nProgress: ${text.progress * 100}%`;
    Alert.alert(text.name, message);
  };

  navigateToDetails = {
    onPress: () => {
      this.props.navigation.navigate("GanttChartDetails");
    },
  };

  formatTasks = () => {
    const formattedTasks = this.state.ganttChartTasks.map(task => {
      return {
        ...task,
        start: new Date(task.start),
        end: new Date(task.end),
      }
    });
    return formattedTasks;
  }
  // Customizable Area End
}
