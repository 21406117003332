import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

interface AssigneeOption1 {
  first_name:string;
  last_name:string;
  id:number
}

interface Tasks1 {
  assignees:{id:number,image:string}[]
  criticality:string
  end_date:string
  id:number
  start_date:string
  task_name:string
  task_sub_category_id:number
  task_sub_category_name:string
}

interface AllTask1 {
  name:string;
  category_id:number;
  tasks:Tasks1[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep:number;
  tableData:string[];
  createTaskForm:boolean;
  editTask:boolean;
  categories:string[];
  taskNameValue:string;
  subCategoryValue:number | undefined;
  assigneeValue:AssigneeOption1[];
  startDate:Date | null;
  endDate:Date | null;
  criticalValue:string | undefined;
  description:string;
  subCategoryOption:{
    id:number;
    sub_category_name:string;
  }[];
  assigneeOption:{
    first_name:string;
    last_name:string;
    id:number
  }[];
  error:boolean;
  allTasks:AllTask1[];
  deleteModal:boolean;
  taskId:number;
  toastOpen:boolean;
  completeModal:boolean;
  completedTaskModal:boolean;
  editTaskId:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryId:string = ""
  getAssigneeApiId:string = ""
  createTaskApiId:string = ""
  deleteTaskApiId:string = ""
  completeTaskApiId:string = ""
  getEditTaskApiId:string = ""
  updateTaskApiId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep:0,
      tableData:[],
      createTaskForm:false,
      editTask:false,
      categories:[],
      taskNameValue:'',
      subCategoryValue:0,
      assigneeValue:[],
      startDate:null,
      endDate:null,
      criticalValue:'',
      description:'',
      subCategoryOption:[],
      assigneeOption:[],
      error:false,
      allTasks:[],
      deleteModal:false,
      taskId:0,
      toastOpen:false,
      completeModal:false,
      completedTaskModal:false,
      editTaskId:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      this.getCategoryRespose(requestCallId,message)
      this.getAssigneeResponse(requestCallId,message)
      this.getTaskResponse(requestCallId,message)
      this.deleteTaskResponse(requestCallId,message)
      this.getCompletedTaskResponse(requestCallId,message)
      this.getEditTaskResponse(requestCallId,message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getCategory();
    this.handleGetAssigneeApiCall()
  }

  getStepContent = (step: number)=> {
    switch (step) {
      case 0:
        return "Step 1";
      case 1:
        return "Step 2";
      case 2:
        return "Step 3";
      default:
        return "Unknown step";
    }
  }
  handleCloseCreateTaskForm = () => {
    this.setState({
      createTaskForm:false,
      editTask:false,
      editTaskId:0,
      taskNameValue:'',
      subCategoryValue:0,
      assigneeValue:[],
      startDate:null,
      endDate:null,
      criticalValue:'',
      description:'',
      error:false
    })
  }
  handleCreateTaskForm = () => {
    this.setState({
      createTaskForm:true
    })
  }

  apiCall = async (data: any) => {
    const { endPoint, apibody, apiMethod } = data;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
  
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
  
    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod ? apiMethod : configJSON.exampleAPiMethod);

    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");

    const body = {
      account_id:userDetails.meta.id,
      company_id:orgDetails.id,
      ...apibody
    }
    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
  
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return apiRequest.messageId;
  };

  getCategory = async() => {
    this.getCategoryId = await this.apiCall({
      endPoint: configJSON.getCategoryEndpoint
    });
  };

  getCategoryRespose = (requestCallId:string,message:Message) => {
    if(this.getCategoryId === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if(responseJson.categories){
          this.setState({
            categories:responseJson.categories
          })
    }
    if(responseJson.all_tasks){
      this.setState({
        allTasks:responseJson.all_tasks
      })
    }
    }
  }
  handleChangeTaskName = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      taskNameValue:event.target.value
    })
  }
  handleStartDate = (date: Date | null) => {
    this.setState({
      startDate: date

    })
  }
  handleChangeEndDate = (date: Date | null) => {
    this.setState({
      endDate:date
    })
  }
  handleChangeDescription = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    if(event.target.value.length < 1000){
      this.setState({
        description:event.target.value
      })
    }
    
  }
  handleGetAssigneeApiCall = async() => {
    const categoryBody ={
      category_id:this.state.activeStep + 1
    }
    this.getAssigneeApiId = await this.apiCall({
      endPoint: configJSON.getAssigneeApiEndpoint,
      apibody:categoryBody
    });
  }
  getAssigneeResponse = (requestCallId:string,message:Message) => {
    if(this.getAssigneeApiId === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if(responseJson.sub_categories){
          this.setState({
            subCategoryOption:responseJson.sub_categories
          })
    }
    if(responseJson.assignees){
      this.setState({
        assigneeOption:responseJson.assignees
      })
    }
    }
  }
  handleChangeAssignee = (event:any,value:{
    first_name: string;
    last_name: string;
    id: number;
    }[]) => {
  if(value?.length <= 3){
    this.setState({assigneeValue:value})
  }
  }
  handleCreateTask = () => {
    if(
      this.state.taskNameValue === "" || 
      this.state.subCategoryValue === 0 || 
      this.state.assigneeValue.length === 0 ||
      this.state.startDate === null ||
      this.state.endDate === null ||
      this.state.criticalValue === "" ||
      this.state.description === ""
    ){
      this.setState({
        error:true
      })
    }else{
      this.setState({
        error:false
      })
      if(this.state.editTask){
        this.handleCallEditTaskApi()
      }else{
        this.handleCallCreateTaskApi()
      }
    }
  }
  handleCallCreateTaskApi = async() => {
    const taskBody ={
      category_id:this.state.activeStep + 1,
      sub_category_id:this.state.subCategoryValue,
      task_name:this.state.taskNameValue,
      task_description:this.state.description,
      start_date:moment(this.state.startDate).format('DD-MM-YYYY'),
      end_date:moment(this.state.endDate).format('DD-MM-YYYY'),
      assignees:this.state.assigneeValue.map(data => data.id),
      criticality:this.state.criticalValue,
    }
    this.createTaskApiId = await this.apiCall({
      endPoint: configJSON.createTaskEndPoint,
      apibody:taskBody
    });
  }
  handleCallEditTaskApi = async() => {
    const edittaskBody ={
      category_id:this.state.activeStep + 1,
      sub_category_id:this.state.subCategoryValue,
      task_name:this.state.taskNameValue,
      task_description:this.state.description,
      start_date:moment(this.state.startDate).format('DD-MM-YYYY'),
      end_date:moment(this.state.endDate).format('DD-MM-YYYY'),
      assignees:this.state.assigneeValue.map(data => data.id),
      criticality:this.state.criticalValue,
      task_id:this.state.editTaskId
    }
    this.updateTaskApiId = await this.apiCall({
      endPoint: configJSON.updateTaskEndPoint,
      apibody:edittaskBody,
      apiMethod:configJSON.updateMethod
    });
  }
  getTaskResponse = (requestCallId:string,message:Message) => {
    if(this.createTaskApiId === requestCallId || this.updateTaskApiId === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if(responseJson.success){
      this.handleCloseCreateTaskForm()
      this.getCategory()
    }
  }
  }
  handleOpenDeleteModal = (taskId:number) => {
    this.setState({
      deleteModal:true,
      taskId:taskId
    })
  }
  handleCloseDeleteModal = () =>{
    this.setState({
      deleteModal:false
    })
  }
  handleDeleteTask = async() => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTaskApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTaskEndpPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");

    const body = {
      account_id:userDetails.meta.id,
      task_id:this.state.taskId
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteTaskResponse = (requestCallId:string,message:Message) => {
    if(this.deleteTaskApiId === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if(responseJson.success){
      this.handleCloseDeleteModal()
      this.getCategory()
    }
  }
  }
  handleToastClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({toastOpen:false})
  };
  handleProceed = async() => {
    const taskbody = {
      category_id:this.state.activeStep + 1,
      completed: false
    }
    this.completeTaskApiId = await this.apiCall({
      endPoint: configJSON.completeTaskEndPoint,
      apibody:taskbody
    });
    
  }
  getCompletedTaskResponse = (requestCallId:string,message:Message) => {
    if(this.completeTaskApiId === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if(responseJson.success){
      this.setState({toastOpen:false,completeModal:false})
      if(responseJson?.main_task?.main_task_completed && (this.state.activeStep + 1 === this.state.categories.length)){
        this.setState({
          completedTaskModal:true
        })
      }else{
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
        }));
      }
      
    }else{
      this.setState({toastOpen:true})
    }
  }
  }
  handleBackStepper = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  }
  handleComplete = () => {
    const tasks = this.state.allTasks.filter(data => data.category_id === this.state.activeStep + 1).map(item => 
      {
        return item.tasks
      })
      const taskLenghth = tasks.map(data =>{ return data.length})
    if(taskLenghth[0] >= 2){
      this.setState({toastOpen:false,completeModal:true})
    }else{
      this.setState({toastOpen:true})
    }
  }
  handleCloseCompleteModal = () => {
    this.setState({
      completeModal:false
    })
  }
  handleCompleteTaskApiCall = async() => {
    const taskbody = {
      category_id:this.state.activeStep + 1,
      completed: true
    }
    this.completeTaskApiId = await this.apiCall({
      endPoint: configJSON.completeTaskEndPoint,
      apibody:taskbody
    });
  }
  handleCloseCompleteTaskModal= () => {
    this.setState({
      completedTaskModal:false,
    },() =>{
    this.handleNavigateActionPlan()
    })
  }
  handleEdit = async(taskId:number) => {
    const taskbody = {
      task_id:taskId
    }
    this.setState({
      editTaskId:taskId
    })
    this.getEditTaskApiId = await this.apiCall({
      endPoint: configJSON.getEditTaskEndPoint,
      apibody:taskbody
    });
  }
  getEditTaskResponse = (requestCallId:string,message:Message) => {
    if(this.getEditTaskApiId === requestCallId){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if(responseJson.success){
        this.setState({
          createTaskForm:true,
          editTask:true,
          taskNameValue:responseJson.task_data.task_name,
          subCategoryValue:responseJson.task_data.sub_category_id,
          assigneeValue:responseJson.task_data.assignees.map((assigneeId:any) => 
            this.state.assigneeOption.find(option => option.id === assigneeId)
          ),
          startDate:responseJson.task_data.start_date,
          endDate:responseJson.task_data.end_date,
          criticalValue:responseJson.task_data.criticality,
          description:responseJson.task_data.task_description
        })
      }
    }
  }
  handleNavigateActionPlan = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ActionPlan'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  // Customizable Area End
}
