import React from 'react';
// Customizable Area Start
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Box, List, ListItem, ListItemText, Container} from '@material-ui/core';
import { 
    logoLight,
    phoneIcon,
    mailIcon,
    locationIcon,
    instaIcon,
    linkedinIcon,
    facebookIcon,
    twitterIcon,
    youTubIcon,
    pintrestIcon,
    whatsappIcon,
    wechatIcon,
    viberIcon,
    tumblrIcon,
    tiktokIcon,
    telegramIcon,
    snapchatIcon,
    redditIcon,
    discordIcon
 } from '../../blocks/DynamicContent/src/assets';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(() => ({
    footerLight: {
        '& .footer-style': {
            background: "#FFF"
        },
        '& .MuiTypography-root ': {
            color: "#000"
        },
        '& .footer-col-title': {
            color: "#000"
        },
        '& .makeStyles-footer-225': {
            fontFamily: "Lato",
            fontWeight: 500
        },
        '& .footer-icon': {
            color: "#000",
            '&:hover': {
                color: "#43D270"
            },
        },
        '& .footer-link': {
            color: '#101010',
        },
        '& .footer-flexLink': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            height: '100%',
            '& img': {
                display: "block",
                width: "auto",
                height: "auto",
            },
            marginBottom: "20px",
        }
    },
    footer: {
        color: "inherit",
        marginTop: "40px",
        paddingBottom: '30px',
        '@media(max-width: 600px)': {
            padding: "30px !important",
        },
    },
    title: {
        color: "#000",
        margin: '0 0 12px',
        fontWeight: 600,
        fontSize: "1.125rem",
    },
    listMenu: {
        padding: "0px",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily:'Lato',
        color: "#5C5C5C",
        marginBottom: '10px',
        '@media (max-width:600px)': {
            marginBottom: "0"
        }
        
    },
    footerLinkLight: {
        color: "#707070",
        textDecoration: 'none',
        fontWeight: 500,
        fontFamily: "Lato",
        lineHeight: "22.4px",
        fontSize: "16px",
    },
    footerLink2: {
        color: "#707070",
        textDecoration: 'none',
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
        lineHeight: "22.4px",
    },
    footerIconBox: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "start",
        flexWrap:'wrap',
        gap:'15px',
        marginTop:'25px',
        '& a': {
            textDecoration: "none",
        }
    },
    footerIcon: {
        color: "inherit",
        width:'30px',
        '&:hover': {
            color: "#43D270"
        },
    },
    linkStyle: {
        color: "#5C5C5C",
        textDecoration: "none",
        cursor: "pointer"
    },
    listItemText: {
        color: "inherit",
        fontSize: "15px",
        fontFamily: "Lato",
        "& span": {
            fontSize: "inherit !important",
        }
    },
    companyDescription:{
        marginTop:'15px',
        width:'333px',
        '& p':{
            lineHeight:'25.6px',
            color:'#000'
        },
        '& p:first-child':{
            marginBottom:'40px'
        }
    },
    addressBox:{
        display:'flex',
        gap:'10px',
        alignItems:'center',
        marginBottom: '24px'
    },
    addressDetails:{
        lineHeight:'22.4px',
        fontWeight: 600,
        fontFamily: 'Lato'
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
    },
    mainContainer:{
        padding:'0'
    },
    gridContainer:{
        paddingTop:'20px',
    },
    footerBoxSecond:{
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-between',
        marginTop: '50px',
        '@media(max-width: 400px)': {
            justifyContent:'center',
        },
},
    footerBoxFirst:{
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-between',
        margin:'0 10px',
        gap:'15px'
    },
    termConditionBox:{
        display:'flex',
        gap:'20px',
        alignItems: 'center',
        marginRight: '20px'
    },
    dividerBox:{
        border:'1px solid #707070',
        opacity:'0.7',
        height:'15px'
    }
}));

// Props types

interface FooterSubAttributes1 {
    position:number;
    title:string;
    link_type:string | null;
    predefined_link:string;
    external_link:string;
    page:string | null;
}
interface FooterSubItems1 {
    id:string;
    type:string;
    attributes:FooterSubAttributes1
}
interface FooterAttributes1 {
    position:number;
    title:string;
    link_type:string | null;
    predefined_link:string;
    external_link:string;
    page:string | null;
    footer_sub_items:{
        data:FooterSubItems1[]
    }
}
interface ContactDetails1 {
    type:string;
    contact_phone:string;
    contact_email:string;
    contact_address:string;
    contact_address_link:string;
    social_media:{
        name:string;
        link:string;
    }[]
}

interface FooterItemProp {
    company_infos:{
        type:string;
        description:string;
    },
    data:{
        id:string;
        type:string;
        attributes:FooterAttributes1
    }[]
    contact_details: ContactDetails1
}

interface Props {
    footerDataFromApi: FooterItemProp;
}

const getNavLinkByType = (item: FooterSubItems1) => {
    const linkType = item?.attributes?.link_type;
    let returnLink = "/";
    if (linkType === "widget_page" && item.attributes.page) {
        returnLink = item.attributes.page
    } else if (linkType === "external" && item.attributes.external_link) {
        returnLink = item?.attributes?.external_link;
    } else if (linkType === "predefined" && item.attributes.predefined_link) {
        returnLink = item?.attributes?.predefined_link;
    }
    return returnLink;
}

const getSocialIcon = (icon: string, classes: string) => {
    switch (icon) {
        case 'Facebook':
            return <img src={facebookIcon} className={classes} alt={icon} />;

        case 'Twitter':
            return <img src={twitterIcon} className={classes} alt={icon} />;

        case 'Instagram':
            return <img src={instaIcon} className={classes} alt={icon} />;

        case 'LinkedIn':
            return <img src={linkedinIcon} className={classes} alt={icon} />;

        case 'YouTube':
            return <img src={youTubIcon} className={classes} alt={icon}/>;

        case 'Pinterest':
            return <img src={pintrestIcon} className={classes} alt={icon}/>;

        case 'Snapchat':
            return <img src={snapchatIcon} className={classes} alt={icon}/>;

        case 'TikTok':
            return <img src={tiktokIcon} className={classes} alt={icon}/>;

        case 'Reddit':
            return <img src={redditIcon} className={classes} alt={icon}/>;

        case 'WhatsApp':
            return <img src={whatsappIcon} className={classes} alt={icon}/>;

        case 'WeChat':
            return <img src={wechatIcon} className={classes} alt={icon}/>;

        case 'Telegram':
            return <img src={telegramIcon} className={classes} alt={icon}/>;

        case 'Tumblr':
            return <img src={tumblrIcon} className={classes} alt={icon}/>;

        case 'Discord':
            return <img src={discordIcon} className={classes} alt={icon}/>;

        case 'Viber':
            return <img src={viberIcon} className={classes} alt={icon}/>;

        default:
            return <>?</>;
    }
};

const SubFooter = (props: Props) => {
    const { footerDataFromApi } = props;
    const logoUrl = logoLight;
    const dense = false;
    const classes = useStyles();

    return <div className={classes.footerLight}>
        <Container className={`${classes.footer} footer-style`}>
                    <Box className={classes.footerBoxFirst}>
                        <Box className={classes.gridContainer}>
                            <Link to="/" className={`footer-flexLink`}>
                                <img src={logoUrl} alt="logo" />
                            </Link>
                            <Typography className={classes.companyDescription}>{parse(footerDataFromApi.company_infos.description.toString())}</Typography>
                        </Box>
                        {footerDataFromApi.data.map((item, index: number) =>{
                                    return(
                                        <Box key={`index${index}`} className={classes.gridContainer}>
                                            <Typography
                                                className={`${classes.title} footer-col-title`}
                                            >
                                                {item?.attributes?.title}
                                            </Typography>
                                            <div>
                                                <List dense={dense}>
                                                    {item?.attributes?.footer_sub_items?.data.map((subItem, index:number) => {
                                                        const externalLink = subItem.attributes.link_type === "external" &&
                                                        subItem.attributes.external_link
                                                            ? subItem.attributes.external_link
                                                            : "";
                                                            if (externalLink) {
                                                                const navigationLink = getNavLinkByType(subItem);
                                                                return (
                                                                    <ListItem
                                                                        key={`footer_plateform_nav${index}`}
                                                                        className={classes.listMenu}
                                                                    >
                                                                        <p
                                                                            data-test-id="external-link"
                                                                            className={classes.linkStyle}
                                                                            onClick={() => window.open(navigationLink)}
                                                                        >
                                                                            <ListItemText
                                                                                primary={subItem.attributes.title}
                                                                                className={classes.listItemText}
                                                                            />
                                                                        </p>
                                                                    </ListItem>
                                                                );
                                                            }
                                                            return (
                                                                <ListItem
                                                                    key={`footer_plateform_nav${index}`}
                                                                    className={classes.listMenu}
                                                                >
                                                                    <Link
                                                                        to={getNavLinkByType(subItem)}
                                                                        className={classes.linkStyle}
                                                                    >
                                                                        <ListItemText
                                                                            primary={subItem.attributes.title}
                                                                            className={classes.listItemText}
                                                                        />
                                                                    </Link>
                                                                </ListItem>
                                                            );
                                                    })}
                                                </List>
                                            </div>
                                        </Box>
                                    )
                                }
                                )}
                        <Box className={classes.gridContainer}>
                        <Typography className={classes.title}>Contact Us</Typography>
                            <Box className={classes.addressBox}>
                                <img src={phoneIcon} alt="phone"/>
                                <Typography className={classes.addressDetails}>{footerDataFromApi.contact_details.contact_phone}</Typography>
                            </Box>
                            <Box className={classes.addressBox}>
                                <img src={mailIcon} alt="mailIcon"/>
                                <a href={`mailto:${footerDataFromApi.contact_details.contact_email}`}
                                   className={classes.addressDetails}>{footerDataFromApi.contact_details.contact_email}</a>
                            </Box>
                            <Box className={classes.addressBox}>
                                <a target="_blank" href={footerDataFromApi.contact_details.contact_address_link}>
                                <img src={locationIcon} alt="locationIcon"/>
                                </a>
                                <a className={classes.addressDetails} target="_blank" href={footerDataFromApi.contact_details.contact_address_link}>{footerDataFromApi.contact_details.contact_address}</a>
                            </Box>
                            <Box className={classes.footerIconBox}>
                            {
                                footerDataFromApi.contact_details.social_media &&
                                footerDataFromApi.contact_details.social_media.length > 0 &&
                                footerDataFromApi.contact_details.social_media.map((socialLink: {
                                    name: string,
                                    link: string,
                                }) => {
                                    return <div style={{cursor: "pointer"}}
                                                onClick={() => window.open(socialLink.link)}>
                                        {
                                            getSocialIcon(socialLink.name, `${classes.footerIcon} footer-icon`)
                                        }
                                    </div>
                                })
                            }
                        </Box>
                        </Box>
                    </Box>
                    <Box className={classes.footerBoxSecond}>
                    <Typography className={classes.footerLink2}>
                        &copy;
                        {new Date().getFullYear()}
                        {' Carbonzeroed. All Rights Reserved.'}
                    </Typography>
                    <Box className={classes.termConditionBox}>
                    <Typography>
                        <Link
                            to="/terms-and-conditions"
                            className={classes.footerLinkLight}
                        >
                            Terms & Conditions
                        </Link>
                    </Typography>
                    <Box className={classes.dividerBox}></Box>
                    <Typography>
                        <Link
                            to="/privacy-policy"
                            className={classes.footerLinkLight}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                    </Box>
                    </Box>
        </Container>
    </div>
}

export default SubFooter;
// Customizable Area End