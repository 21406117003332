import React from "react";
import "gantt-task-react/dist/index.css";

import {
  // Customizable Area Start
  // Customizable Area End
  Box,
} from "@mui/material";
// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import "@mui/styles";
import Layout from "../../dashboard/src/Layout.web";

import { Gantt } from "gantt-task-react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GanttChartController, {
  configJSON,
  Props,
} from "./GanttChartController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class GanttChart extends GanttChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Layout>
            <div style={webStyle.mainBox}>
              <div style={webStyle.ganttBoxWrapper}>
                <Gantt
                  tasks={this.formatTasks()}
                  viewMode={this.state.ganttChartView}
                  listCellWidth={this.state.isChecked ? "155px" : ""}
                  columnWidth={this.state.ganttChartColumnWidth}
                />
              </div>
            </div>
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    padding: "20px",
  },
  viewSwitcherWrapper: {
    marginTop: "10px",
  },
  ganttBoxWrapper: {
    marginTop: "20px",
  },
  buttonStyle: {
    marginLeft: "10px",
    border: "none",
    backgroundColor: "#F0F0F0",
  },
  chkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};
// Customizable Area End
